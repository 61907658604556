import { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { FormikCustomSelector } from 'components/Controls';
import { searchCustomersWithExternal } from 'shared/AsyncSearchUtils/searchCustomersWithExternal';
import { NAME_MATCHING_SOURCE } from '../consts';

export const CustomerSelectorCell = ({
  cell,
  row,
  fuzzyErrors,
  organization,
  customerIdSourceAndType,
  formRef,
  CustomSingleOption,
}) => {
  const [selectOptions, setSelectOptions] = useState([]);

  const value = cell?.value;
  const fuzzyError = fuzzyErrors?.[row.original.rowIndex]?.customer_id;

  const isNameMatching = customerIdSourceAndType?.source === NAME_MATCHING_SOURCE;

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce(async ({ searchQuery }) => {
      const response = await searchCustomersWithExternal({
        customerIdSourceAndType,
        searchQuery,
        orgId: organization?.id,
        paginated: false,
      });
      setSelectOptions(response ?? []);
    }, 500),
    [customerIdSourceAndType, organization?.id],
  );

  useEffect(() => {
    if (value) {
      debouncedSearch({ searchQuery: value });
    } else {
      setSelectOptions([]);
    }

    // Cancel any ongoing debounced calls when component unmounts or dependencies change
    return () => debouncedSearch.cancel();
  }, [value, organization, debouncedSearch]);

  return (
    <>
      <FormikCustomSelector
        height={32}
        useDefaultSelect={true}
        components={{ Option: CustomSingleOption }}
        placeholder="Select id..."
        minWidth={180}
        menuWidth={220}
        errorWithoutTooltip
        value={value ? { value: value, label: value } : null}
        isClearable={true}
        options={selectOptions}
        name={`[${row.original.rowIndex}].customer_id`}
        hasWarning={!!fuzzyError}
        tooltipInputDisplay={fuzzyError ? `Search for ${fuzzyError?.searchedName}` : null}
        handleChange={(option) => {
          formRef?.current?.setFieldValue(
            `[${row.original.rowIndex}].customer_id`,
            isNameMatching ? option?.name ?? option?.value : option?.value ?? null,
          );
        }}
      />
    </>
  );
};
