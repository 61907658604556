import { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { FormikCustomSelector } from 'components/Controls';
import { searchInvoicingSchedules } from 'shared/AsyncSearchUtils/searchInvoicingSchedules';

export const InvoicingSchedulesCell = ({ cell, row, organization, formRef, CustomSingleOption }) => {
  const [selectOptions, setSelectOptions] = useState([]);

  const value = cell?.value;

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce(async ({ searchQuery }) => {
      const response = await searchInvoicingSchedules({
        searchQuery,
        orgId: organization?.id,
      });
      setSelectOptions(response ?? []);
    }, 500),
    [organization?.id],
  );

  useEffect(() => {
    if (value) {
      debouncedSearch({ searchQuery: value });
    } else {
      setSelectOptions([]);
    }

    // Cancel any ongoing debounced calls when component unmounts or dependencies change
    return () => debouncedSearch.cancel();
  }, [value, organization, debouncedSearch]);

  return (
    <FormikCustomSelector
      isClearable={true}
      height={32}
      useDefaultSelect={true}
      components={{ Option: CustomSingleOption }}
      placeholder="Select id..."
      minWidth={160}
      menuWidth={200}
      errorWithoutTooltip
      value={value ? { value: value, label: value } : null}
      options={selectOptions}
      noSort
      handleChange={(option) => {
        if (option) {
          formRef?.current?.setFieldValue(`[${row.original.rowIndex}].invoicing_schedule_id`, option.value);
        } else {
          formRef?.current?.setFieldValue(`[${row.original.rowIndex}].invoicing_schedule_id`, null);
        }
      }}
      name={`[${row.original.rowIndex}].invoicing_schedule_id`}
    />
  );
};
