import { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { FormikCustomSelector } from 'components/Controls';
import { searchInvoices } from 'shared/AsyncSearchUtils/searchInvoices';
import { getInvoicesCellsValue } from '../utils';
import { NAME_MATCHING_SOURCE } from '../consts';

export const InvoicesSelectorCell = ({
  cell,
  row,
  organization,
  customerIdSourceAndType,
  formRef,
  CustomSingleOption,
}) => {
  const [selectOptions, setSelectOptions] = useState([]);

  const value = cell?.value;

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce(async ({ searchQuery }) => {
      const response = await searchInvoices({ searchQuery, orgId: organization?.id });
      setSelectOptions(response ?? []);
    }, 500),
    [organization?.id],
  );

  useEffect(() => {
    if (value) {
      debouncedSearch({ searchQuery: value });
    } else {
      setSelectOptions([]);
    }

    // Cancel any ongoing debounced calls when component unmounts or dependencies change
    return () => debouncedSearch.cancel();
  }, [value, organization, debouncedSearch]);

  const isCustomerNameMatching = customerIdSourceAndType?.source === NAME_MATCHING_SOURCE;

  return (
    <FormikCustomSelector
      isClearable={true}
      height={32}
      useDefaultSelect={true}
      components={{ Option: CustomSingleOption }}
      placeholder="Select id..."
      minWidth={160}
      menuWidth={200}
      errorWithoutTooltip
      value={value ? { value: value, label: value } : null}
      options={selectOptions}
      noSort
      handleChange={(option) => {
        if (option) {
          formRef?.current?.setFieldValue(`[${row.original.rowIndex}].id`, option.value);

          getInvoicesCellsValue({
            isCustomerNameMatching,
            products: organization?.products,
            formRefCurrent: formRef.current,
            invoice: option?.allFields,
            index: row.original.rowIndex,
          });
        } else {
          formRef?.current?.setFieldValue(`[${row.original.rowIndex}].id`, null);
        }
      }}
      name={`[${row.original.rowIndex}].id`}
    />
  );
};
